//export const API_URL = "https://matilde-api-dev.plesh.co"
export const API_URL = "https://dacia-empowerment-api-23-zkg2f7nlea-ew.a.run.app"

export const CURRENT_SESSION_ID = "1024"

export const sessions = [
    {id: '1024', label: "Ottobre 2024", disabled: false, slotWebinar: ""},
    {id: '0524', label: "Maggio 2024", disabled: false, slotWebinar: "13lun1012", link: "https://player.vimeo.com/video/945734557?h=19e973ad3e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},
    {id: '1023', label: "Ottobre 2023", disabled: false, slotWebinar: "10mar1012", link: "https://player.vimeo.com/video/873296431?h=6449c21081&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"},
    {id: '0723', label: "Luglio 2023", disabled: false, slotWebinar: "05mer1012", link: "https://player.vimeo.com/video/842458284?h=79423cb817&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},
]